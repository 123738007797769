body {
  margin: 0;
  overflow: hidden;
}

.wrapper {
  position: relative;
  min-height: 100vh;
}

[class|=confetti] {
  position: absolute;
}

.confetti-0 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 52%;
  opacity: 1.0988840882;
  transform: rotate(210.9596625558deg);
  -webkit-animation: drop-0 4.4553146939s 0.6376073666s infinite;
          animation: drop-0 4.4553146939s 0.6376073666s infinite;
}

@-webkit-keyframes drop-0 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-1 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 54%;
  opacity: 1.4686931526;
  transform: rotate(337.6930147715deg);
  -webkit-animation: drop-1 4.6689799636s 0.0590063727s infinite;
          animation: drop-1 4.6689799636s 0.0590063727s infinite;
}

@-webkit-keyframes drop-1 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.confetti-2 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 4%;
  opacity: 1.3352813093;
  transform: rotate(111.3379192335deg);
  -webkit-animation: drop-2 4.5076480038s 0.9642802738s infinite;
          animation: drop-2 4.5076480038s 0.9642802738s infinite;
}

@-webkit-keyframes drop-2 {
  100% {
    top: 110%;
    left: 10%;
  }
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-3 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 63%;
  opacity: 0.9320192666;
  transform: rotate(49.1224920824deg);
  -webkit-animation: drop-3 4.1395144983s 0.1759177653s infinite;
          animation: drop-3 4.1395144983s 0.1759177653s infinite;
}

@-webkit-keyframes drop-3 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-4 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 18%;
  opacity: 1.1166167244;
  transform: rotate(89.7453663844deg);
  -webkit-animation: drop-4 4.7873758579s 0.7517798199s infinite;
          animation: drop-4 4.7873758579s 0.7517798199s infinite;
}

@-webkit-keyframes drop-4 {
  100% {
    top: 110%;
    left: 26%;
  }
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-5 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 100%;
  opacity: 0.683995026;
  transform: rotate(130.4268804769deg);
  -webkit-animation: drop-5 4.9816147727s 0.7891623294s infinite;
          animation: drop-5 4.9816147727s 0.7891623294s infinite;
}

@-webkit-keyframes drop-5 {
  100% {
    top: 110%;
    left: 111%;
  }
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.confetti-6 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 6%;
  opacity: 1.4549760287;
  transform: rotate(205.070784495deg);
  -webkit-animation: drop-6 4.7210995989s 0.0666100049s infinite;
          animation: drop-6 4.7210995989s 0.0666100049s infinite;
}

@-webkit-keyframes drop-6 {
  100% {
    top: 110%;
    left: 16%;
  }
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 16%;
  }
}
.confetti-7 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 13%;
  opacity: 0.6340262321;
  transform: rotate(218.3425869718deg);
  -webkit-animation: drop-7 4.899562284s 0.0494607024s infinite;
          animation: drop-7 4.899562284s 0.0494607024s infinite;
}

@-webkit-keyframes drop-7 {
  100% {
    top: 110%;
    left: 15%;
  }
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 15%;
  }
}
.confetti-8 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 78%;
  opacity: 0.6785378069;
  transform: rotate(225.6015594951deg);
  -webkit-animation: drop-8 4.8374311159s 0.6637123674s infinite;
          animation: drop-8 4.8374311159s 0.6637123674s infinite;
}

@-webkit-keyframes drop-8 {
  100% {
    top: 110%;
    left: 87%;
  }
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 87%;
  }
}
.confetti-9 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 95%;
  opacity: 1.3000697873;
  transform: rotate(234.2909271468deg);
  -webkit-animation: drop-9 4.2340526961s 0.0701254068s infinite;
          animation: drop-9 4.2340526961s 0.0701254068s infinite;
}

@-webkit-keyframes drop-9 {
  100% {
    top: 110%;
    left: 102%;
  }
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-10 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 80%;
  opacity: 0.7502826704;
  transform: rotate(249.5686971444deg);
  -webkit-animation: drop-10 4.0020581418s 0.0246137044s infinite;
          animation: drop-10 4.0020581418s 0.0246137044s infinite;
}

@-webkit-keyframes drop-10 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-11 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 14%;
  opacity: 1.4780725439;
  transform: rotate(354.9711484337deg);
  -webkit-animation: drop-11 4.5285727388s 0.7890249131s infinite;
          animation: drop-11 4.5285727388s 0.7890249131s infinite;
}

@-webkit-keyframes drop-11 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-12 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 53%;
  opacity: 0.9347445413;
  transform: rotate(316.1376999115deg);
  -webkit-animation: drop-12 4.5143015441s 0.3227516645s infinite;
          animation: drop-12 4.5143015441s 0.3227516645s infinite;
}

@-webkit-keyframes drop-12 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-13 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 1.4494267512;
  transform: rotate(185.4717422677deg);
  -webkit-animation: drop-13 4.2270791879s 0.6271966814s infinite;
          animation: drop-13 4.2270791879s 0.6271966814s infinite;
}

@-webkit-keyframes drop-13 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-14 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 99%;
  opacity: 0.9704157805;
  transform: rotate(242.535699151deg);
  -webkit-animation: drop-14 4.1324403239s 0.7503267888s infinite;
          animation: drop-14 4.1324403239s 0.7503267888s infinite;
}

@-webkit-keyframes drop-14 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.confetti-15 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 38%;
  opacity: 1.2035827654;
  transform: rotate(197.9613847894deg);
  -webkit-animation: drop-15 4.2650851584s 0.1540869365s infinite;
          animation: drop-15 4.2650851584s 0.1540869365s infinite;
}

@-webkit-keyframes drop-15 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-16 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 6%;
  opacity: 1.1951083716;
  transform: rotate(328.7286872662deg);
  -webkit-animation: drop-16 4.7249878504s 0.0260724234s infinite;
          animation: drop-16 4.7249878504s 0.0260724234s infinite;
}

@-webkit-keyframes drop-16 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-17 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 30%;
  opacity: 0.9001940129;
  transform: rotate(45.0308989458deg);
  -webkit-animation: drop-17 4.1451566682s 0.4206771174s infinite;
          animation: drop-17 4.1451566682s 0.4206771174s infinite;
}

@-webkit-keyframes drop-17 {
  100% {
    top: 110%;
    left: 41%;
  }
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-18 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 17%;
  opacity: 0.8661840264;
  transform: rotate(205.958328193deg);
  -webkit-animation: drop-18 4.7068786003s 0.59331628s infinite;
          animation: drop-18 4.7068786003s 0.59331628s infinite;
}

@-webkit-keyframes drop-18 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-19 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 25%;
  opacity: 0.7914904769;
  transform: rotate(120.3762995392deg);
  -webkit-animation: drop-19 4.9382860582s 0.5771301045s infinite;
          animation: drop-19 4.9382860582s 0.5771301045s infinite;
}

@-webkit-keyframes drop-19 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-20 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 41%;
  opacity: 1.3067426482;
  transform: rotate(206.5971859055deg);
  -webkit-animation: drop-20 4.3056922044s 0.8359837582s infinite;
          animation: drop-20 4.3056922044s 0.8359837582s infinite;
}

@-webkit-keyframes drop-20 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.confetti-21 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 34%;
  opacity: 0.7712217329;
  transform: rotate(338.7517719552deg);
  -webkit-animation: drop-21 4.1130373075s 0.7663891502s infinite;
          animation: drop-21 4.1130373075s 0.7663891502s infinite;
}

@-webkit-keyframes drop-21 {
  100% {
    top: 110%;
    left: 49%;
  }
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 49%;
  }
}
.confetti-22 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 46%;
  opacity: 0.6492420693;
  transform: rotate(113.4506898035deg);
  -webkit-animation: drop-22 4.7728968501s 0.2391902992s infinite;
          animation: drop-22 4.7728968501s 0.2391902992s infinite;
}

@-webkit-keyframes drop-22 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-23 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 48%;
  opacity: 0.8552659129;
  transform: rotate(163.5149072194deg);
  -webkit-animation: drop-23 4.2250434268s 0.4628509561s infinite;
          animation: drop-23 4.2250434268s 0.4628509561s infinite;
}

@-webkit-keyframes drop-23 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-24 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 1.0198706305;
  transform: rotate(85.4164440942deg);
  -webkit-animation: drop-24 4.9985833494s 0.910655995s infinite;
          animation: drop-24 4.9985833494s 0.910655995s infinite;
}

@-webkit-keyframes drop-24 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-25 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 76%;
  opacity: 0.6111132778;
  transform: rotate(73.7398009129deg);
  -webkit-animation: drop-25 4.7895529132s 0.1534761009s infinite;
          animation: drop-25 4.7895529132s 0.1534761009s infinite;
}

@-webkit-keyframes drop-25 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-26 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 19%;
  opacity: 0.5380771925;
  transform: rotate(354.3855079357deg);
  -webkit-animation: drop-26 4.7256338953s 0.279842497s infinite;
          animation: drop-26 4.7256338953s 0.279842497s infinite;
}

@-webkit-keyframes drop-26 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.confetti-27 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 37%;
  opacity: 0.8965754542;
  transform: rotate(16.2827335886deg);
  -webkit-animation: drop-27 4.9456709193s 0.9761048192s infinite;
          animation: drop-27 4.9456709193s 0.9761048192s infinite;
}

@-webkit-keyframes drop-27 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-28 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 49%;
  opacity: 0.5244032114;
  transform: rotate(28.2445831823deg);
  -webkit-animation: drop-28 4.0219307056s 0.4881731781s infinite;
          animation: drop-28 4.0219307056s 0.4881731781s infinite;
}

@-webkit-keyframes drop-28 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-29 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 89%;
  opacity: 0.5066775142;
  transform: rotate(43.5095234868deg);
  -webkit-animation: drop-29 4.4614123597s 0.3664077768s infinite;
          animation: drop-29 4.4614123597s 0.3664077768s infinite;
}

@-webkit-keyframes drop-29 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-30 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 91%;
  opacity: 1.1507828467;
  transform: rotate(242.0076639143deg);
  -webkit-animation: drop-30 4.3978217167s 0.9481390992s infinite;
          animation: drop-30 4.3978217167s 0.9481390992s infinite;
}

@-webkit-keyframes drop-30 {
  100% {
    top: 110%;
    left: 96%;
  }
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 96%;
  }
}
.confetti-31 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 2%;
  opacity: 1.4035798399;
  transform: rotate(128.2706919781deg);
  -webkit-animation: drop-31 4.9022412722s 0.7532941685s infinite;
          animation: drop-31 4.9022412722s 0.7532941685s infinite;
}

@-webkit-keyframes drop-31 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-32 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 44%;
  opacity: 0.8147527633;
  transform: rotate(198.83423228deg);
  -webkit-animation: drop-32 4.5793782816s 0.2222603717s infinite;
          animation: drop-32 4.5793782816s 0.2222603717s infinite;
}

@-webkit-keyframes drop-32 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-33 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 31%;
  opacity: 0.9559960768;
  transform: rotate(152.4898517362deg);
  -webkit-animation: drop-33 4.3968930828s 0.21046081s infinite;
          animation: drop-33 4.3968930828s 0.21046081s infinite;
}

@-webkit-keyframes drop-33 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.confetti-34 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 63%;
  opacity: 1.1302408221;
  transform: rotate(239.4248552664deg);
  -webkit-animation: drop-34 4.5457426909s 0.8881022645s infinite;
          animation: drop-34 4.5457426909s 0.8881022645s infinite;
}

@-webkit-keyframes drop-34 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-35 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 40%;
  opacity: 0.6333942182;
  transform: rotate(33.5863421086deg);
  -webkit-animation: drop-35 4.6306720472s 0.5859671983s infinite;
          animation: drop-35 4.6306720472s 0.5859671983s infinite;
}

@-webkit-keyframes drop-35 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.confetti-36 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 25%;
  opacity: 1.3454744253;
  transform: rotate(193.6487259741deg);
  -webkit-animation: drop-36 4.8505081574s 0.6817064278s infinite;
          animation: drop-36 4.8505081574s 0.6817064278s infinite;
}

@-webkit-keyframes drop-36 {
  100% {
    top: 110%;
    left: 26%;
  }
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-37 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 11%;
  opacity: 1.4637925398;
  transform: rotate(85.7616719279deg);
  -webkit-animation: drop-37 4.5780754507s 0.0557821548s infinite;
          animation: drop-37 4.5780754507s 0.0557821548s infinite;
}

@-webkit-keyframes drop-37 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-38 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 82%;
  opacity: 0.6087345636;
  transform: rotate(319.892716689deg);
  -webkit-animation: drop-38 4.7365633567s 0.2562900923s infinite;
          animation: drop-38 4.7365633567s 0.2562900923s infinite;
}

@-webkit-keyframes drop-38 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-39 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 51%;
  opacity: 0.7974579846;
  transform: rotate(109.8104455536deg);
  -webkit-animation: drop-39 4.635852531s 0.4470722222s infinite;
          animation: drop-39 4.635852531s 0.4470722222s infinite;
}

@-webkit-keyframes drop-39 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-40 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 77%;
  opacity: 1.307398044;
  transform: rotate(103.5518222482deg);
  -webkit-animation: drop-40 4.4874719362s 0.7163135419s infinite;
          animation: drop-40 4.4874719362s 0.7163135419s infinite;
}

@-webkit-keyframes drop-40 {
  100% {
    top: 110%;
    left: 88%;
  }
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-41 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  opacity: 1.0358501986;
  transform: rotate(160.1337012638deg);
  -webkit-animation: drop-41 4.0034307205s 0.2153240769s infinite;
          animation: drop-41 4.0034307205s 0.2153240769s infinite;
}

@-webkit-keyframes drop-41 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-42 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 6%;
  opacity: 0.5178558994;
  transform: rotate(264.9533379876deg);
  -webkit-animation: drop-42 4.5406248805s 0.6310004701s infinite;
          animation: drop-42 4.5406248805s 0.6310004701s infinite;
}

@-webkit-keyframes drop-42 {
  100% {
    top: 110%;
    left: 9%;
  }
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 9%;
  }
}
.confetti-43 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 43%;
  opacity: 0.6020398823;
  transform: rotate(7.8342424994deg);
  -webkit-animation: drop-43 4.1342544844s 0.2485520953s infinite;
          animation: drop-43 4.1342544844s 0.2485520953s infinite;
}

@-webkit-keyframes drop-43 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-44 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 2%;
  opacity: 0.8395276041;
  transform: rotate(307.6483258651deg);
  -webkit-animation: drop-44 4.6019657954s 0.4535525091s infinite;
          animation: drop-44 4.6019657954s 0.4535525091s infinite;
}

@-webkit-keyframes drop-44 {
  100% {
    top: 110%;
    left: 10%;
  }
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-45 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 35%;
  opacity: 1.0130872817;
  transform: rotate(250.4550361241deg);
  -webkit-animation: drop-45 4.6556249366s 0.1107947871s infinite;
          animation: drop-45 4.6556249366s 0.1107947871s infinite;
}

@-webkit-keyframes drop-45 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.confetti-46 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 22%;
  opacity: 0.5391980871;
  transform: rotate(71.4548801655deg);
  -webkit-animation: drop-46 4.7269163741s 0.7282697199s infinite;
          animation: drop-46 4.7269163741s 0.7282697199s infinite;
}

@-webkit-keyframes drop-46 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-47 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 76%;
  opacity: 1.4307615463;
  transform: rotate(355.7581152414deg);
  -webkit-animation: drop-47 4.7370860195s 0.6297045804s infinite;
          animation: drop-47 4.7370860195s 0.6297045804s infinite;
}

@-webkit-keyframes drop-47 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-48 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 99%;
  opacity: 1.1258360594;
  transform: rotate(197.3665343134deg);
  -webkit-animation: drop-48 4.1923213142s 0.2023361394s infinite;
          animation: drop-48 4.1923213142s 0.2023361394s infinite;
}

@-webkit-keyframes drop-48 {
  100% {
    top: 110%;
    left: 105%;
  }
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 105%;
  }
}
.confetti-49 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 8%;
  opacity: 0.9021696023;
  transform: rotate(240.6557983253deg);
  -webkit-animation: drop-49 4.6508110069s 0.0311490345s infinite;
          animation: drop-49 4.6508110069s 0.0311490345s infinite;
}

@-webkit-keyframes drop-49 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-50 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 55%;
  opacity: 0.5384234692;
  transform: rotate(178.0941829389deg);
  -webkit-animation: drop-50 4.2853132777s 0.6420635674s infinite;
          animation: drop-50 4.2853132777s 0.6420635674s infinite;
}

@-webkit-keyframes drop-50 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-51 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 39%;
  opacity: 0.8850207825;
  transform: rotate(173.554730084deg);
  -webkit-animation: drop-51 4.3347226737s 0.9079926939s infinite;
          animation: drop-51 4.3347226737s 0.9079926939s infinite;
}

@-webkit-keyframes drop-51 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-52 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 20%;
  opacity: 1.0408558019;
  transform: rotate(120.9002880743deg);
  -webkit-animation: drop-52 4.9989015014s 0.010060985s infinite;
          animation: drop-52 4.9989015014s 0.010060985s infinite;
}

@-webkit-keyframes drop-52 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-53 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 64%;
  opacity: 1.3341354441;
  transform: rotate(310.719242017deg);
  -webkit-animation: drop-53 4.1232177126s 0.374713613s infinite;
          animation: drop-53 4.1232177126s 0.374713613s infinite;
}

@-webkit-keyframes drop-53 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-54 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  opacity: 0.5272573833;
  transform: rotate(148.8420477736deg);
  -webkit-animation: drop-54 4.8362058072s 0.2370820658s infinite;
          animation: drop-54 4.8362058072s 0.2370820658s infinite;
}

@-webkit-keyframes drop-54 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-55 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 53%;
  opacity: 0.6529884509;
  transform: rotate(262.9637488016deg);
  -webkit-animation: drop-55 4.0586574761s 0.940199169s infinite;
          animation: drop-55 4.0586574761s 0.940199169s infinite;
}

@-webkit-keyframes drop-55 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-56 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 91%;
  opacity: 0.7349420497;
  transform: rotate(120.7493585697deg);
  -webkit-animation: drop-56 4.4981142387s 0.483167448s infinite;
          animation: drop-56 4.4981142387s 0.483167448s infinite;
}

@-webkit-keyframes drop-56 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.confetti-57 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 64%;
  opacity: 1.4119327962;
  transform: rotate(12.2789951029deg);
  -webkit-animation: drop-57 4.8466769724s 0.1847999093s infinite;
          animation: drop-57 4.8466769724s 0.1847999093s infinite;
}

@-webkit-keyframes drop-57 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-58 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 78%;
  opacity: 1.4257225097;
  transform: rotate(129.4951316226deg);
  -webkit-animation: drop-58 4.9675926302s 0.5254266472s infinite;
          animation: drop-58 4.9675926302s 0.5254266472s infinite;
}

@-webkit-keyframes drop-58 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-59 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 45%;
  opacity: 1.0725701268;
  transform: rotate(333.3667097605deg);
  -webkit-animation: drop-59 4.1275205732s 0.9391329571s infinite;
          animation: drop-59 4.1275205732s 0.9391329571s infinite;
}

@-webkit-keyframes drop-59 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-60 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 12%;
  opacity: 0.7816575362;
  transform: rotate(324.418117058deg);
  -webkit-animation: drop-60 4.4070504637s 0.7946448652s infinite;
          animation: drop-60 4.4070504637s 0.7946448652s infinite;
}

@-webkit-keyframes drop-60 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.confetti-61 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 79%;
  opacity: 1.1057588794;
  transform: rotate(74.5295722317deg);
  -webkit-animation: drop-61 4.5064791656s 0.7794875441s infinite;
          animation: drop-61 4.5064791656s 0.7794875441s infinite;
}

@-webkit-keyframes drop-61 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-62 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 14%;
  opacity: 0.5746733353;
  transform: rotate(196.0799108798deg);
  -webkit-animation: drop-62 4.4777587514s 0.1800114956s infinite;
          animation: drop-62 4.4777587514s 0.1800114956s infinite;
}

@-webkit-keyframes drop-62 {
  100% {
    top: 110%;
    left: 22%;
  }
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-63 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 36%;
  opacity: 0.7537747081;
  transform: rotate(67.9110159338deg);
  -webkit-animation: drop-63 4.3645006632s 0.5267656948s infinite;
          animation: drop-63 4.3645006632s 0.5267656948s infinite;
}

@-webkit-keyframes drop-63 {
  100% {
    top: 110%;
    left: 49%;
  }
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 49%;
  }
}
.confetti-64 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 100%;
  opacity: 1.2816971705;
  transform: rotate(17.2718745535deg);
  -webkit-animation: drop-64 4.9312055307s 0.0081286283s infinite;
          animation: drop-64 4.9312055307s 0.0081286283s infinite;
}

@-webkit-keyframes drop-64 {
  100% {
    top: 110%;
    left: 114%;
  }
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 114%;
  }
}
.confetti-65 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 10%;
  opacity: 1.1462381139;
  transform: rotate(93.2666069588deg);
  -webkit-animation: drop-65 4.0566668407s 0.1631749147s infinite;
          animation: drop-65 4.0566668407s 0.1631749147s infinite;
}

@-webkit-keyframes drop-65 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-66 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 90%;
  opacity: 1.3566994849;
  transform: rotate(219.5343439432deg);
  -webkit-animation: drop-66 4.3059592289s 0.4680702265s infinite;
          animation: drop-66 4.3059592289s 0.4680702265s infinite;
}

@-webkit-keyframes drop-66 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.confetti-67 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 36%;
  opacity: 0.5262361743;
  transform: rotate(153.7762631023deg);
  -webkit-animation: drop-67 4.7364994098s 0.7564886885s infinite;
          animation: drop-67 4.7364994098s 0.7564886885s infinite;
}

@-webkit-keyframes drop-67 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.confetti-68 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 98%;
  opacity: 0.6396006756;
  transform: rotate(90.4452244275deg);
  -webkit-animation: drop-68 4.2842259608s 0.1034453003s infinite;
          animation: drop-68 4.2842259608s 0.1034453003s infinite;
}

@-webkit-keyframes drop-68 {
  100% {
    top: 110%;
    left: 102%;
  }
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-69 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 72%;
  opacity: 1.0752389631;
  transform: rotate(151.881433648deg);
  -webkit-animation: drop-69 4.8276831119s 0.0730357977s infinite;
          animation: drop-69 4.8276831119s 0.0730357977s infinite;
}

@-webkit-keyframes drop-69 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-70 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 93%;
  opacity: 0.6368348951;
  transform: rotate(215.1313159598deg);
  -webkit-animation: drop-70 4.1042813814s 0.8435753983s infinite;
          animation: drop-70 4.1042813814s 0.8435753983s infinite;
}

@-webkit-keyframes drop-70 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-71 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 6%;
  opacity: 0.9644464322;
  transform: rotate(165.0610137562deg);
  -webkit-animation: drop-71 4.1356711542s 0.6268558463s infinite;
          animation: drop-71 4.1356711542s 0.6268558463s infinite;
}

@-webkit-keyframes drop-71 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-72 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 0.7423395392;
  transform: rotate(108.0792297422deg);
  -webkit-animation: drop-72 4.9952226337s 0.8046798109s infinite;
          animation: drop-72 4.9952226337s 0.8046798109s infinite;
}

@-webkit-keyframes drop-72 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-73 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 62%;
  opacity: 0.963275107;
  transform: rotate(263.6963434096deg);
  -webkit-animation: drop-73 4.4450839446s 0.6943191866s infinite;
          animation: drop-73 4.4450839446s 0.6943191866s infinite;
}

@-webkit-keyframes drop-73 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-74 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 1%;
  opacity: 0.6982515809;
  transform: rotate(194.6421090234deg);
  -webkit-animation: drop-74 4.6724461413s 0.9268738977s infinite;
          animation: drop-74 4.6724461413s 0.9268738977s infinite;
}

@-webkit-keyframes drop-74 {
  100% {
    top: 110%;
    left: 9%;
  }
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 9%;
  }
}
.confetti-75 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 55%;
  opacity: 1.2995093389;
  transform: rotate(100.8406863525deg);
  -webkit-animation: drop-75 4.6317291458s 0.0392295029s infinite;
          animation: drop-75 4.6317291458s 0.0392295029s infinite;
}

@-webkit-keyframes drop-75 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-76 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 40%;
  opacity: 1.1922729103;
  transform: rotate(147.8527230284deg);
  -webkit-animation: drop-76 4.7230471824s 0.1666375373s infinite;
          animation: drop-76 4.7230471824s 0.1666375373s infinite;
}

@-webkit-keyframes drop-76 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-77 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 98%;
  opacity: 0.5204032112;
  transform: rotate(44.1093958305deg);
  -webkit-animation: drop-77 4.7235996292s 0.8338406622s infinite;
          animation: drop-77 4.7235996292s 0.8338406622s infinite;
}

@-webkit-keyframes drop-77 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-78 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 56%;
  opacity: 0.5948110513;
  transform: rotate(249.6427343488deg);
  -webkit-animation: drop-78 4.4771077427s 0.2365608174s infinite;
          animation: drop-78 4.4771077427s 0.2365608174s infinite;
}

@-webkit-keyframes drop-78 {
  100% {
    top: 110%;
    left: 64%;
  }
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.confetti-79 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 70%;
  opacity: 0.6309157463;
  transform: rotate(39.2496466867deg);
  -webkit-animation: drop-79 4.1152993627s 0.7771521512s infinite;
          animation: drop-79 4.1152993627s 0.7771521512s infinite;
}

@-webkit-keyframes drop-79 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-80 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 1.1581099545;
  transform: rotate(336.8877856205deg);
  -webkit-animation: drop-80 4.7417689534s 0.1925183997s infinite;
          animation: drop-80 4.7417689534s 0.1925183997s infinite;
}

@-webkit-keyframes drop-80 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-81 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 50%;
  opacity: 1.0868946958;
  transform: rotate(105.1265854926deg);
  -webkit-animation: drop-81 4.4733724174s 0.5078185433s infinite;
          animation: drop-81 4.4733724174s 0.5078185433s infinite;
}

@-webkit-keyframes drop-81 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-82 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 1.3882172112;
  transform: rotate(190.3300464758deg);
  -webkit-animation: drop-82 4.7898369646s 0.6651986722s infinite;
          animation: drop-82 4.7898369646s 0.6651986722s infinite;
}

@-webkit-keyframes drop-82 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-83 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 45%;
  opacity: 1.2487731767;
  transform: rotate(328.9438427633deg);
  -webkit-animation: drop-83 4.3216005583s 0.5787956936s infinite;
          animation: drop-83 4.3216005583s 0.5787956936s infinite;
}

@-webkit-keyframes drop-83 {
  100% {
    top: 110%;
    left: 52%;
  }
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.confetti-84 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 28%;
  opacity: 0.7893076985;
  transform: rotate(207.5431990112deg);
  -webkit-animation: drop-84 4.7586406598s 0.900835957s infinite;
          animation: drop-84 4.7586406598s 0.900835957s infinite;
}

@-webkit-keyframes drop-84 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-85 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 75%;
  opacity: 0.5444683784;
  transform: rotate(132.7357892644deg);
  -webkit-animation: drop-85 4.577648017s 0.8491617538s infinite;
          animation: drop-85 4.577648017s 0.8491617538s infinite;
}

@-webkit-keyframes drop-85 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-86 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 96%;
  opacity: 1.0216563852;
  transform: rotate(326.8738731503deg);
  -webkit-animation: drop-86 4.9624504576s 0.7687287158s infinite;
          animation: drop-86 4.9624504576s 0.7687287158s infinite;
}

@-webkit-keyframes drop-86 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-87 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 68%;
  opacity: 0.783608832;
  transform: rotate(124.3673758649deg);
  -webkit-animation: drop-87 4.9012129262s 0.6268428707s infinite;
          animation: drop-87 4.9012129262s 0.6268428707s infinite;
}

@-webkit-keyframes drop-87 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-88 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 46%;
  opacity: 1.1540569029;
  transform: rotate(346.7776211469deg);
  -webkit-animation: drop-88 4.8645427882s 0.773661605s infinite;
          animation: drop-88 4.8645427882s 0.773661605s infinite;
}

@-webkit-keyframes drop-88 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.confetti-89 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 79%;
  opacity: 0.8203341118;
  transform: rotate(177.9363279248deg);
  -webkit-animation: drop-89 4.7898833634s 0.7131652842s infinite;
          animation: drop-89 4.7898833634s 0.7131652842s infinite;
}

@-webkit-keyframes drop-89 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-90 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 21%;
  opacity: 1.2556931939;
  transform: rotate(195.9397270394deg);
  -webkit-animation: drop-90 4.5073531623s 0.8709398304s infinite;
          animation: drop-90 4.5073531623s 0.8709398304s infinite;
}

@-webkit-keyframes drop-90 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-91 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 95%;
  opacity: 0.7667879905;
  transform: rotate(355.9764918287deg);
  -webkit-animation: drop-91 4.4306163684s 0.7505461822s infinite;
          animation: drop-91 4.4306163684s 0.7505461822s infinite;
}

@-webkit-keyframes drop-91 {
  100% {
    top: 110%;
    left: 102%;
  }
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-92 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 56%;
  opacity: 1.4383221793;
  transform: rotate(72.6163549872deg);
  -webkit-animation: drop-92 4.4177419736s 0.5482375422s infinite;
          animation: drop-92 4.4177419736s 0.5482375422s infinite;
}

@-webkit-keyframes drop-92 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-93 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 6%;
  opacity: 0.8586246009;
  transform: rotate(139.3374179044deg);
  -webkit-animation: drop-93 4.1195300996s 0.0204773511s infinite;
          animation: drop-93 4.1195300996s 0.0204773511s infinite;
}

@-webkit-keyframes drop-93 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-94 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 75%;
  opacity: 0.8931451012;
  transform: rotate(42.9960193402deg);
  -webkit-animation: drop-94 4.8293319561s 0.0800112331s infinite;
          animation: drop-94 4.8293319561s 0.0800112331s infinite;
}

@-webkit-keyframes drop-94 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-95 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  opacity: 0.7668619887;
  transform: rotate(69.5317916622deg);
  -webkit-animation: drop-95 4.8466871594s 0.7812884011s infinite;
          animation: drop-95 4.8466871594s 0.7812884011s infinite;
}

@-webkit-keyframes drop-95 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-96 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 65%;
  opacity: 1.4774137715;
  transform: rotate(264.5011168987deg);
  -webkit-animation: drop-96 4.6574557938s 0.8105462618s infinite;
          animation: drop-96 4.6574557938s 0.8105462618s infinite;
}

@-webkit-keyframes drop-96 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-97 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 86%;
  opacity: 1.4089365561;
  transform: rotate(155.1930064361deg);
  -webkit-animation: drop-97 4.5206362026s 0.8732272632s infinite;
          animation: drop-97 4.5206362026s 0.8732272632s infinite;
}

@-webkit-keyframes drop-97 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-98 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 74%;
  opacity: 0.9958190465;
  transform: rotate(261.1495905977deg);
  -webkit-animation: drop-98 4.9317631916s 0.7189466875s infinite;
          animation: drop-98 4.9317631916s 0.7189466875s infinite;
}

@-webkit-keyframes drop-98 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-99 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 29%;
  opacity: 1.294835836;
  transform: rotate(43.9101769237deg);
  -webkit-animation: drop-99 4.0016646181s 0.3717905583s infinite;
          animation: drop-99 4.0016646181s 0.3717905583s infinite;
}

@-webkit-keyframes drop-99 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-100 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 71%;
  opacity: 1.1698174045;
  transform: rotate(32.9343581856deg);
  -webkit-animation: drop-100 4.8282866963s 0.8932370133s infinite;
          animation: drop-100 4.8282866963s 0.8932370133s infinite;
}

@-webkit-keyframes drop-100 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-101 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 18%;
  opacity: 1.2690775439;
  transform: rotate(18.6534591565deg);
  -webkit-animation: drop-101 4.0332517331s 0.8375053199s infinite;
          animation: drop-101 4.0332517331s 0.8375053199s infinite;
}

@-webkit-keyframes drop-101 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-101 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.confetti-102 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 34%;
  opacity: 1.284517221;
  transform: rotate(313.1677449deg);
  -webkit-animation: drop-102 4.1196925681s 0.131226287s infinite;
          animation: drop-102 4.1196925681s 0.131226287s infinite;
}

@-webkit-keyframes drop-102 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-102 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-103 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 47%;
  opacity: 0.5521429296;
  transform: rotate(165.7887014702deg);
  -webkit-animation: drop-103 4.8539961139s 0.7599131381s infinite;
          animation: drop-103 4.8539961139s 0.7599131381s infinite;
}

@-webkit-keyframes drop-103 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-103 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-104 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 86%;
  opacity: 1.329131394;
  transform: rotate(25.0705131215deg);
  -webkit-animation: drop-104 4.6417056778s 0.2358102248s infinite;
          animation: drop-104 4.6417056778s 0.2358102248s infinite;
}

@-webkit-keyframes drop-104 {
  100% {
    top: 110%;
    left: 91%;
  }
}

@keyframes drop-104 {
  100% {
    top: 110%;
    left: 91%;
  }
}
.confetti-105 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 65%;
  opacity: 0.7452910258;
  transform: rotate(65.7556694527deg);
  -webkit-animation: drop-105 4.770612816s 0.0371256927s infinite;
          animation: drop-105 4.770612816s 0.0371256927s infinite;
}

@-webkit-keyframes drop-105 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-105 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-106 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 38%;
  opacity: 0.8827411448;
  transform: rotate(337.6144365432deg);
  -webkit-animation: drop-106 4.0377001279s 0.9485228115s infinite;
          animation: drop-106 4.0377001279s 0.9485228115s infinite;
}

@-webkit-keyframes drop-106 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-106 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-107 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 13%;
  opacity: 0.7321598188;
  transform: rotate(246.5462899512deg);
  -webkit-animation: drop-107 4.5804640058s 0.0172685681s infinite;
          animation: drop-107 4.5804640058s 0.0172685681s infinite;
}

@-webkit-keyframes drop-107 {
  100% {
    top: 110%;
    left: 18%;
  }
}

@keyframes drop-107 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-108 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 6%;
  opacity: 0.602171808;
  transform: rotate(6.8650266295deg);
  -webkit-animation: drop-108 4.975082386s 0.4179245939s infinite;
          animation: drop-108 4.975082386s 0.4179245939s infinite;
}

@-webkit-keyframes drop-108 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-108 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-109 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 80%;
  opacity: 0.8424583503;
  transform: rotate(57.4438277059deg);
  -webkit-animation: drop-109 4.4489322964s 0.4301448111s infinite;
          animation: drop-109 4.4489322964s 0.4301448111s infinite;
}

@-webkit-keyframes drop-109 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-109 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-110 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 27%;
  opacity: 0.835891502;
  transform: rotate(308.622381398deg);
  -webkit-animation: drop-110 4.6727222366s 0.0880579212s infinite;
          animation: drop-110 4.6727222366s 0.0880579212s infinite;
}

@-webkit-keyframes drop-110 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-110 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-111 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 88%;
  opacity: 1.2335141511;
  transform: rotate(126.4380548325deg);
  -webkit-animation: drop-111 4.1388044067s 0.4322272636s infinite;
          animation: drop-111 4.1388044067s 0.4322272636s infinite;
}

@-webkit-keyframes drop-111 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-111 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-112 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 30%;
  opacity: 1.2399997983;
  transform: rotate(322.7645226576deg);
  -webkit-animation: drop-112 4.7255583859s 0.3612198831s infinite;
          animation: drop-112 4.7255583859s 0.3612198831s infinite;
}

@-webkit-keyframes drop-112 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-112 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-113 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 18%;
  opacity: 0.7760387357;
  transform: rotate(194.6100969743deg);
  -webkit-animation: drop-113 4.3229439996s 0.1246504519s infinite;
          animation: drop-113 4.3229439996s 0.1246504519s infinite;
}

@-webkit-keyframes drop-113 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-113 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-114 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 14%;
  opacity: 1.3428423326;
  transform: rotate(214.3866646246deg);
  -webkit-animation: drop-114 4.6591111305s 0.2525846289s infinite;
          animation: drop-114 4.6591111305s 0.2525846289s infinite;
}

@-webkit-keyframes drop-114 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-114 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-115 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 8%;
  opacity: 1.1825289408;
  transform: rotate(201.3731758007deg);
  -webkit-animation: drop-115 4.3807461564s 0.8460985677s infinite;
          animation: drop-115 4.3807461564s 0.8460985677s infinite;
}

@-webkit-keyframes drop-115 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-115 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-116 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 1.0258975405;
  transform: rotate(211.4025166997deg);
  -webkit-animation: drop-116 4.1461601507s 0.1048123436s infinite;
          animation: drop-116 4.1461601507s 0.1048123436s infinite;
}

@-webkit-keyframes drop-116 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-116 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-117 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 39%;
  opacity: 0.671787315;
  transform: rotate(135.3673918712deg);
  -webkit-animation: drop-117 4.185373234s 0.3155468851s infinite;
          animation: drop-117 4.185373234s 0.3155468851s infinite;
}

@-webkit-keyframes drop-117 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-117 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-118 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 52%;
  opacity: 0.9201861688;
  transform: rotate(302.1143832211deg);
  -webkit-animation: drop-118 4.7088507452s 0.150673064s infinite;
          animation: drop-118 4.7088507452s 0.150673064s infinite;
}

@-webkit-keyframes drop-118 {
  100% {
    top: 110%;
    left: 66%;
  }
}

@keyframes drop-118 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-119 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 33%;
  opacity: 0.8745187624;
  transform: rotate(17.3703110758deg);
  -webkit-animation: drop-119 4.9829990455s 0.1604264821s infinite;
          animation: drop-119 4.9829990455s 0.1604264821s infinite;
}

@-webkit-keyframes drop-119 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-119 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-120 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 45%;
  opacity: 0.9509818957;
  transform: rotate(144.0399985807deg);
  -webkit-animation: drop-120 4.775523165s 0.9465668587s infinite;
          animation: drop-120 4.775523165s 0.9465668587s infinite;
}

@-webkit-keyframes drop-120 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-120 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-121 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 82%;
  opacity: 0.9214637194;
  transform: rotate(37.4617115129deg);
  -webkit-animation: drop-121 4.9864555066s 0.3995242717s infinite;
          animation: drop-121 4.9864555066s 0.3995242717s infinite;
}

@-webkit-keyframes drop-121 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-121 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.confetti-122 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 15%;
  opacity: 0.8077641113;
  transform: rotate(261.2381554841deg);
  -webkit-animation: drop-122 4.1971741642s 0.7689006698s infinite;
          animation: drop-122 4.1971741642s 0.7689006698s infinite;
}

@-webkit-keyframes drop-122 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-122 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-123 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 28%;
  opacity: 1.3516175884;
  transform: rotate(254.808371543deg);
  -webkit-animation: drop-123 4.3200577793s 0.6081162324s infinite;
          animation: drop-123 4.3200577793s 0.6081162324s infinite;
}

@-webkit-keyframes drop-123 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-123 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-124 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 68%;
  opacity: 1.4003731123;
  transform: rotate(304.5110166911deg);
  -webkit-animation: drop-124 4.0065139827s 0.0589034363s infinite;
          animation: drop-124 4.0065139827s 0.0589034363s infinite;
}

@-webkit-keyframes drop-124 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-124 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-125 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 49%;
  opacity: 1.0429272304;
  transform: rotate(196.8008176159deg);
  -webkit-animation: drop-125 4.1133144032s 0.1487692226s infinite;
          animation: drop-125 4.1133144032s 0.1487692226s infinite;
}

@-webkit-keyframes drop-125 {
  100% {
    top: 110%;
    left: 51%;
  }
}

@keyframes drop-125 {
  100% {
    top: 110%;
    left: 51%;
  }
}
.confetti-126 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 83%;
  opacity: 0.8697807926;
  transform: rotate(56.3993299755deg);
  -webkit-animation: drop-126 4.2496406182s 0.5096199017s infinite;
          animation: drop-126 4.2496406182s 0.5096199017s infinite;
}

@-webkit-keyframes drop-126 {
  100% {
    top: 110%;
    left: 87%;
  }
}

@keyframes drop-126 {
  100% {
    top: 110%;
    left: 87%;
  }
}
.confetti-127 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 39%;
  opacity: 0.9417422553;
  transform: rotate(85.4767829317deg);
  -webkit-animation: drop-127 4.1890153278s 0.1883583351s infinite;
          animation: drop-127 4.1890153278s 0.1883583351s infinite;
}

@-webkit-keyframes drop-127 {
  100% {
    top: 110%;
    left: 52%;
  }
}

@keyframes drop-127 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.confetti-128 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 66%;
  opacity: 1.2750548506;
  transform: rotate(176.4563100517deg);
  -webkit-animation: drop-128 4.3606524352s 0.0128208559s infinite;
          animation: drop-128 4.3606524352s 0.0128208559s infinite;
}

@-webkit-keyframes drop-128 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-128 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-129 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 68%;
  opacity: 1.0254484635;
  transform: rotate(36.1134037235deg);
  -webkit-animation: drop-129 4.6394274517s 0.8102202962s infinite;
          animation: drop-129 4.6394274517s 0.8102202962s infinite;
}

@-webkit-keyframes drop-129 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-129 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-130 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 1.4870735394;
  transform: rotate(80.0099924124deg);
  -webkit-animation: drop-130 4.3749484549s 0.0165415272s infinite;
          animation: drop-130 4.3749484549s 0.0165415272s infinite;
}

@-webkit-keyframes drop-130 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-130 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-131 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 84%;
  opacity: 0.8248799694;
  transform: rotate(26.5397979391deg);
  -webkit-animation: drop-131 4.2757060562s 0.1756144132s infinite;
          animation: drop-131 4.2757060562s 0.1756144132s infinite;
}

@-webkit-keyframes drop-131 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-131 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-132 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 1.3846450454;
  transform: rotate(109.3103392148deg);
  -webkit-animation: drop-132 4.4276408864s 0.3959614363s infinite;
          animation: drop-132 4.4276408864s 0.3959614363s infinite;
}

@-webkit-keyframes drop-132 {
  100% {
    top: 110%;
    left: 30%;
  }
}

@keyframes drop-132 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-133 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 62%;
  opacity: 1.1681378828;
  transform: rotate(260.2255343415deg);
  -webkit-animation: drop-133 4.01970173s 0.0459446571s infinite;
          animation: drop-133 4.01970173s 0.0459446571s infinite;
}

@-webkit-keyframes drop-133 {
  100% {
    top: 110%;
    left: 69%;
  }
}

@keyframes drop-133 {
  100% {
    top: 110%;
    left: 69%;
  }
}
.confetti-134 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 82%;
  opacity: 0.5485882242;
  transform: rotate(52.3882950591deg);
  -webkit-animation: drop-134 4.6030662895s 0.5441540724s infinite;
          animation: drop-134 4.6030662895s 0.5441540724s infinite;
}

@-webkit-keyframes drop-134 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-134 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-135 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 5%;
  opacity: 1.331883186;
  transform: rotate(92.7804728592deg);
  -webkit-animation: drop-135 4.3269489737s 0.018389951s infinite;
          animation: drop-135 4.3269489737s 0.018389951s infinite;
}

@-webkit-keyframes drop-135 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-135 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-136 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 32%;
  opacity: 1.149801935;
  transform: rotate(181.2125409521deg);
  -webkit-animation: drop-136 4.4454044497s 0.6703303276s infinite;
          animation: drop-136 4.4454044497s 0.6703303276s infinite;
}

@-webkit-keyframes drop-136 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-136 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.confetti-137 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 96%;
  opacity: 0.7304637508;
  transform: rotate(228.3446281834deg);
  -webkit-animation: drop-137 4.0643984717s 0.6702003598s infinite;
          animation: drop-137 4.0643984717s 0.6702003598s infinite;
}

@-webkit-keyframes drop-137 {
  100% {
    top: 110%;
    left: 105%;
  }
}

@keyframes drop-137 {
  100% {
    top: 110%;
    left: 105%;
  }
}
.confetti-138 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 0.711076772;
  transform: rotate(65.5355306869deg);
  -webkit-animation: drop-138 4.8299494744s 0.3959545641s infinite;
          animation: drop-138 4.8299494744s 0.3959545641s infinite;
}

@-webkit-keyframes drop-138 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-138 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.confetti-139 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 88%;
  opacity: 1.0098725198;
  transform: rotate(37.8397348065deg);
  -webkit-animation: drop-139 4.8107076101s 0.5988394377s infinite;
          animation: drop-139 4.8107076101s 0.5988394377s infinite;
}

@-webkit-keyframes drop-139 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-139 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.confetti-140 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 0.9367712413;
  transform: rotate(277.0091094396deg);
  -webkit-animation: drop-140 4.7792885808s 0.7528376296s infinite;
          animation: drop-140 4.7792885808s 0.7528376296s infinite;
}

@-webkit-keyframes drop-140 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-140 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-141 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 11%;
  opacity: 1.2118616884;
  transform: rotate(37.014919058deg);
  -webkit-animation: drop-141 4.2979580016s 0.968253211s infinite;
          animation: drop-141 4.2979580016s 0.968253211s infinite;
}

@-webkit-keyframes drop-141 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-141 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.confetti-142 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 69%;
  opacity: 0.8764660143;
  transform: rotate(56.7563018593deg);
  -webkit-animation: drop-142 4.1645259122s 0.8896449665s infinite;
          animation: drop-142 4.1645259122s 0.8896449665s infinite;
}

@-webkit-keyframes drop-142 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-142 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-143 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 9%;
  opacity: 0.6581340237;
  transform: rotate(158.584412605deg);
  -webkit-animation: drop-143 4.4309320706s 0.9188914251s infinite;
          animation: drop-143 4.4309320706s 0.9188914251s infinite;
}

@-webkit-keyframes drop-143 {
  100% {
    top: 110%;
    left: 10%;
  }
}

@keyframes drop-143 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-144 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 66%;
  opacity: 1.4609286212;
  transform: rotate(47.1782607211deg);
  -webkit-animation: drop-144 4.0232292197s 0.8536711926s infinite;
          animation: drop-144 4.0232292197s 0.8536711926s infinite;
}

@-webkit-keyframes drop-144 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-144 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-145 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 32%;
  opacity: 0.5816866557;
  transform: rotate(328.5674333493deg);
  -webkit-animation: drop-145 4.804818058s 0.1619503567s infinite;
          animation: drop-145 4.804818058s 0.1619503567s infinite;
}

@-webkit-keyframes drop-145 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-145 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-146 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 25%;
  opacity: 1.2642533718;
  transform: rotate(165.4596355631deg);
  -webkit-animation: drop-146 4.2265151217s 0.2025764582s infinite;
          animation: drop-146 4.2265151217s 0.2025764582s infinite;
}

@-webkit-keyframes drop-146 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-146 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-147 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 51%;
  opacity: 0.5368358659;
  transform: rotate(167.2131661083deg);
  -webkit-animation: drop-147 4.0392693829s 0.7861235989s infinite;
          animation: drop-147 4.0392693829s 0.7861235989s infinite;
}

@-webkit-keyframes drop-147 {
  100% {
    top: 110%;
    left: 55%;
  }
}

@keyframes drop-147 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-148 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 59%;
  opacity: 1.0076461666;
  transform: rotate(150.9906922743deg);
  -webkit-animation: drop-148 4.2884026335s 0.0118007816s infinite;
          animation: drop-148 4.2884026335s 0.0118007816s infinite;
}

@-webkit-keyframes drop-148 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-148 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-149 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 46%;
  opacity: 1.1132312445;
  transform: rotate(17.7921876802deg);
  -webkit-animation: drop-149 4.5708493006s 0.5725716409s infinite;
          animation: drop-149 4.5708493006s 0.5725716409s infinite;
}

@-webkit-keyframes drop-149 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-149 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-150 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 87%;
  opacity: 1.0155828141;
  transform: rotate(68.8802507852deg);
  -webkit-animation: drop-150 4.0647052096s 0.1825127671s infinite;
          animation: drop-150 4.0647052096s 0.1825127671s infinite;
}

@-webkit-keyframes drop-150 {
  100% {
    top: 110%;
    left: 101%;
  }
}

@keyframes drop-150 {
  100% {
    top: 110%;
    left: 101%;
  }
}

.wavy{
 background-color: #e5e5f7;

background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 9px ), repeating-linear-gradient( #777fc155, #777fc1 );
}